<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('ball_game.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('ball_game.head_data') }}</v-card-title>

        <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-col cols="12" xs="6" md="6" xl="6">
                <v-text-field v-model="formData.title" :label="$t('ball_game.detail_input1')" outlined dense
                  disabled></v-text-field>
              </v-col>

              <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                <v-text-field v-model="formData.round" :label="$t('ball_game.detail_input2')" type="date" :min="minDate"
                  outlined dense disabled></v-text-field>
              </v-col>
            </v-row>
          </v-card-actions>

          <!-- Reward type and amount Input -->
          <v-col>
            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('ball_game.head1') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="4" class="py-0">
                  <v-text-field v-model="formData.match[0].homeName" :label="$t('ball_game.detail_input3')" outlined dense
                    disabled></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field v-model="formData.match[0].homeScore" :rules="formRule.number"
                    :label="$t('ball_game.detail_input5')" outlined dense :disabled="disabledResult"></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field v-model="formData.match[0].awayScore" :rules="formRule.number"
                    :label="$t('ball_game.detail_input6')" outlined dense :disabled="disabledResult"></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-text-field v-model="formData.match[0].awayName" :label="$t('ball_game.detail_input4')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('ball_game.head2') }} </v-card-title>
              </v-row>
              <v-row>
                <v-col cols="4" class="py-0">
                  <v-text-field v-model="formData.match[1].homeName" :label="$t('ball_game.detail_input3')" outlined dense
                    disabled></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field v-model="formData.match[1].homeScore" :rules="formRule.number"
                    :label="$t('ball_game.detail_input5')" outlined dense :disabled="disabledResult"></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field v-model="formData.match[1].awayScore" :rules="formRule.number"
                    :label="$t('ball_game.detail_input6')" outlined dense :disabled="disabledResult"></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-text-field v-model="formData.match[1].awayName" :label="$t('ball_game.detail_input4')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-col>

          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'predict-football' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" class="px-3" :disabled="disabledResult" @click="submitForm" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t('ball_game.header7') }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      loading: false,
      formValid: true,
      formRule: {
        number: [
          value => (value !== undefined && value !== null && value !== '') || this.$t('alert_list.list2'),
          value => /^[0-9]+$/.test(value) || this.$t('alert_list.list2'),
        ],
      },
      formData: {
        title: '',
        round: '',
        timeStart: '',
        timeEnd: '',
        typeBetUse: 'coin',
        betUse: 1,
        typeReward: 'money',
        rewardAmount: null,
        multiplyTurnOver: 0,
        matchAmount: 2,
        match: [
          { homeName: '', awayName: '' },
          { homeName: '', awayName: '' },
        ],
      },
      minDate: moment().format('YYYY-MM-DD'),
      rewardTypes: ['point', 'coin', 'money'],
      disabledResult: true,

      breadcrumbsItems: [
        {
          text: this.$t('ball_game.head'),
          disabled: false,
          href: '/predictFootball',
        },
        {
          text: this.$t('ball_game.head_list2'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

    this.getFootballData()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'ทายบอล',
            url: window.location.href,
            detail: 'รายละเอียด ทายบอล',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('alert'),
            text: `${this.$t('ball_game.confirm_update')}${this.formData.title}`,
            showCancelButton: true,
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          })
          .then(async action => {
            if (action.isConfirmed) {
              await this.updateResultFootball()
            }
          })
      }
    },

    async getFootballData() {
      const id = this.$route.params.id
      this.loading = true
      try {
        let res = await this.$store.dispatch('getFootballList', `id=${id}`)
        let resData = res.data[0]

        function isEmptyValues(...values) {
          for (const value of values) {
            if (value === null || value === '') {
              return true
            }
          }
          return false
        }

        // // check can send result from time end and result values
        const currentTime = moment().format('YYYY-MM-DD HH:mm')
        const timeEnd = moment(resData.timeEnd).format('YYYY-MM-DD HH:mm')

        if (
          moment(currentTime).isAfter(timeEnd) &&
          isEmptyValues(resData.match[0].homeScore) &&
          isEmptyValues(resData.match[0].awayScore) &&
          isEmptyValues(resData.match[1].homeScore) &&
          isEmptyValues(resData.match[1].awayScore)
        ) {
          this.disabledResult = false
        }

        this.formData = {
          id: resData.id,
          title: resData.title,
          round: moment(resData.round).format('YYYY-MM-DD') || null,
          timeStart: moment(resData.timeStart).format('YYYY-MM-DD') || null,
          timeEnd: moment(resData.timeEnd).format('YYYY-MM-DD') || null,
          typeBetUse: resData.typeBetUse,
          betUse: resData.betUse,
          typeReward: resData.typeReward,
          rewardAmount: resData.rewardAmount,
          multiplyTurnOver: resData.multiplyTurnOver,
          match: resData.match,
        }

        this.loading = false
      } catch (e) { }
    },

    async updateResultFootball() {
      const id = this.$route.params.id
      const data = {
        id: this.formData.id,
        match: [
          {
            matchId: this.formData.match[0].id,
            homeScore: this.formData.match[0].homeScore,
            awayScore: this.formData.match[0].awayScore,
          },
          {
            matchId: this.formData.match[1].id,
            homeScore: this.formData.match[1].homeScore,
            awayScore: this.formData.match[1].awayScore,
          },
        ],
      }
      this.loading = true
      try {
        let res = await this.$store.dispatch('updateResultFootball', data)
        if (res.status === 'success') {
          this.$swal.fire(this.$t('ball_game.sended_complete'), '', 'success').then(action => {
            this.$router.push({ name: 'predict-football-result', params: id })
          })
        } else if (res.code === 400) {
          this.$swal.fire(`${this.$t('ball_game.sended_fail1_1')} ${this.formData.title} ${this.$t('ball_game.sended_fail1_2')}`, '', 'error')
        } else {
          this.$swal.fire(this.$t('ball_game.sended_fail2'), '', 'error')
        }
        this.loading = false
      } catch (e) { }
    },
  },
}
</script>
